.breadcrumbs {
  font-size: 1.1rem;
  font-family: $font-family-base;
  background: $drop-down-gray;
  padding: 1rem 3.1rem;
  height: 38px;
  max-width: 100%;
  width: 100%;
  margin: 0;

  @include media-breakpoint-down(md) {
    padding: 1rem 2rem;
  }

  .item {
    color: $black;
    font-weight: bold;

    &:last-child {
      font-weight: normal;
    }

    &:not(:last-child) {

      a {
        font-weight: bold;
        color: $black;
      }

      &::after {
        margin: 0;
        content: '\/';
        font-family: $font-family-base;
        font-weight: bold;
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}
