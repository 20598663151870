.modal-popup {

    .modal-inner-wrap {
        border-radius: 30px;
        max-width: 1000px;

        .modal-header {
            height: 82px;
        }

        .action-close {
            margin: 0;
            right: 58px;
            top: 0;

            @include media-breakpoint-up(md) {
                right: 82px;
                top: 0px;
            }

            &::before {
                content: '';
                width: 52px;
                height: 52px;
                position: absolute;
                display: block;
                background-image: url(../images/icon-cross.svg);
                background-size: cover;
            }
        }
    }

    &.modal-slide {

        @include media-breakpoint-down(md) {
            left: 0;
            padding: 2rem;
        }

        .modal-inner-wrap[class] {
            background-color: $white;
        }
    }

    .modal-footer {
        display: block;
    }

    .finapps-modal {
        padding-bottom: 3rem;

        .modal-content {
            padding: 0;

            .calculator-wrapper {
                max-width: 844px;
                margin: 0 auto;

                h1,
                .h1-title {
                    font-size: 2.6rem;
                }

                .columna {

                    >h1 {

                        @include media-breakpoint-up(md) {
                            text-align: left;
                        }
                    }

                    .finance-information {
                        padding: 0;

                        .renglon label {
                            font-size: 1.8rem;
                            text-align: left;
                        }
                    }

                    &.finance-details {

                        .renglon {

                            @include media-breakpoint-up(md) {
                                margin: 0 0 0 auto;
                                width: 90%;
                            }

                            .payment-result {
                                margin: 0;
                            }

                            .disclaimer {
                                font-size: 1.1rem;
                            }
                        }

                        .apply-now {
                            margin: 0 auto 2rem;
                            padding: 2rem 0;
                            font-size: 2.2rem;
                        }

                        h1.repayments {
                            font-size: 3.2rem !important;
                        }

                    }
                }

                .calculator-header {
                    position: relative;
                    padding-top: 7rem;

                    .finapps-poweredby-logo {
                        max-width: 75%;
                    }

                    @include media-breakpoint-up(md) {
                        padding-top: 0;

                        .poweredby-logo-wrapper {
                            justify-content: start;
                            padding: 0 0 0 2.5rem;
                        }
                    }

                    .partner-logo-wrapper {
                        width: 100%;
                        position: absolute;
                        right: 0;
                        top: -20px;
                        padding: 0;
                        justify-content: center;
                        height: 120px;

                        @include media-breakpoint-up(md) {
                            justify-content: end;
                            width: 50%;
                            right: 20px;
                            top: -30px;
                        }
                        
                        img {
                            height: auto;
                            max-height: 147px;
                            z-index: -1;
                        }
                    }
                }

                .calculator-footer {

                    .finapps-logo {
                        max-width: 70%;
                    }

                    p>a,
                    p {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}