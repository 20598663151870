/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 19, 2021 */
@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/roboto-black-webfont.woff2') format('woff2'),
    url('../fonts/roboto-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
    url('../fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('../fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src:
    url('../fonts/oswald-medium-webfont.woff2') format('woff2'),
    url('../fonts/oswald-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
