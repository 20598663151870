%account-page-title {

  .page-title-wrapper {

    .page-title {
      font-size: 4rem;
      font-family: $headings-font-family;
      font-weight: 500;
      margin-bottom: 4.1rem;
    }
  }
}

%account-section-title {

  .block-title {
    padding: 1.6rem 2rem;
    border-bottom: none !important;
    color: $primary;
    display: flex;
    justify-content: space-between;
    background: $drop-down-gray;

    strong {
      font-weight: 700 !important;
      font-size: 1.8rem !important;
    }

    .edit,
    .view {
      color: $black;
      font-size: 1.4rem;
      text-decoration: underline;
      text-transform: none;

      span {
        vertical-align: middle;
        letter-spacing: 0;
      }
    }
  }
}

%account-order-section {

  .block-dashboard-orders {

    .block-content {
      padding: 0 2rem;

      .orders-recent {
        margin: 0;
        border-bottom: none;

        .table-order-items.table {
          color: $black;

          .action {
            color: $black;
            text-decoration: underline;
            text-transform: none;
            letter-spacing: 0;
          }

          td,
          th {
            border-top: none;
            border-bottom: none;
            padding: 0.5rem 0;
            width: auto;
          }

          @include media-breakpoint-down(md) {

            tr {
              padding: 1rem 0;
            }
          }
        }
      }
    }
  }
}

// input form
%input-svg-icon {

  .control {
    position: relative;

    input {
      padding: 1.35rem 2rem 1.35rem 4.7rem;

      &:focus {
        box-shadow: $btn-box-shadow;
        border-color: $gray-800;
        opacity: 1;
      }
    }

    input[name=firstname],
    input[name=lastname] {
      padding: 1.35rem 2rem;
    }

    > span {
      position: absolute;
      top: 14px;
      left: 19px;
      z-index: 3;

      svg {
        width: 22px !important;
      }
    }
  }
}

%account-address-block-content {

  .block-content {
    padding: 1.5rem 2rem;

    .box {

      .box-title {
        margin-bottom: 0.6rem;

        span {
          font-size: 1.4rem;
          font-weight: 700;
          line-height: 2.4rem;
        }
      }

      .box-content {
        font-size: 1.4rem;
        line-height: 2.4rem;

        address {

          a {
            color: $black;
            font-size: 1.4rem;
          }
        }
      }
    }

    .limiter-options {
      min-width: 65px;
    }
  }
}

.sales-order-history,
.customer-account-index,
.customer-address-index,
.customer-account-edit,
.sales-order-view,
.customer-address-form {

  .page-main {
    max-width: 1366px;

    .columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .column.main {
        max-width: 864px;
        padding: 0;
        @extend %account-page-title;

        @include media-breakpoint-down(lg) {

          .block-title {

            &::after,
            &::before {
              content: none !important;
            }

            .edit {
              margin-top: 0;
            }
          }
        }
      }

      @include media-breakpoint-down (lg) {
        flex-direction: column;

        .sidebar.sidebar-main,
        .column.main {
          max-width: none;
          width: 100% !important;
        }
      }

      @include media-breakpoint-down(md) {

        .sidebar.sidebar-main {
          order: -1;
        }

        .block-collapsible-nav {
          position: static;
          margin: 0;

          .content {
            border-bottom: none;
          }
        }
      }
    }
  }

  // sidebar
  .sidebar.sidebar-main {
    max-width: 315px;
    width: 100% !important;
    padding: 0;
    float: none;
    margin-bottom: 2rem;

    .block-collapsible-nav-title {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      background-image: url(../images/minus-black.svg);
      background-size: 1rem 1rem;
      background-position: right 1.5rem top 2rem;
      background-repeat: no-repeat;
      padding: 1.1rem 1.3rem;
      border-bottom: 1px solid $account-nav-gray;
      cursor: pointer;

      &[aria-expanded=false] {
        background-image: url(../images/plus-black.svg);
      }

      &::after {
        content: '';
      }

      strong {
        text-transform: uppercase;
        font-size: 1.8rem;
        font-family: $headings-font-family;
        line-height: 2.6rem;
        color: $black;
      }
    }

    .block-collapsible-nav-content {
      background: $white;

      li {
        padding: 0 0 1.4rem;

        &.current > strong {
          border-color: $white;
        }

        strong,
        a {
          font-size: 1.3rem;
          color: $black;
          border-left: 0;
        }

        a:not(.logout) {
          text-decoration: underline;

          &:hover {
            background-color: $white;
            color: $primary;
          }
        }

        .logout {
          height: 4rem;
          text-transform: uppercase;
          color: $white;
          font-family: $headings-font-family;
          padding: 0.9rem 8rem;
          background: $primary;
          border: 1px solid $primary;
          border-radius: 2px;
          line-height: 2.2rem;

          &:hover {
            color: $primary;
            background: $white;
          }
        }
      }
    }
  }
}

.customer-account-index,
.sales-order-history {
  @extend %account-order-section;

  .column.main {

    .block-dashboard-info,
    .block-dashboard-addresses,
    .block-dashboard-orders {
      @extend %account-section-title;
    }
  }
}

// account dashboard
.customer-account-index {
  // main content
  .column.main {
    float: none;

    .block-dashboard-info,
    .block-dashboard-addresses,
    .block-dashboard-orders {
      margin-bottom: 0.4rem;

      @extend %account-address-block-content;
    }

    .page-title-wrapper {
      display: none;
    }
  }
}

// my orders page
.sales-order-history {

  .block-dashboard-orders {

    .block-title a {
      display: none;
    }
  }

  .block-content {
    display: flex;
    flex-direction: column;

    .message.info.empty {
      background: $white;
      color: $gray-900;
      padding-left: 0;

      span {
        font-size: 1.4rem;
      }

      span::before {
        display: none;
      }
    }

    .pager {
      order: 10;

      .limiter {
        margin: 1rem 0;

        .limiter-options {
          background-position: right center;
        }
      }
    }
  }
}

// Customer details page
.customer-account-edit {

  .form-edit-account {

    .fieldset.info {
      float: none;
      width: 100%;
      @extend %account-section-title;

      .custom-control {
        padding-top: 1.5rem;

        .custom-control-input {

          &:not(:disabled):active ~ .custom-control-label::before,
          &:checked ~ .custom-control-label::before {
            border: 1px solid $black;
          }

          &:focus ~ .custom-control-label::before {
            border: 1px solid $black;
            box-shadow: none;
          }
        }

        .custom-control-label {
          font-size: 1.4rem;
          vertical-align: top;
          font-weight: 700;
          padding-left: 2.5rem;

          &::before,
          &::after {
            left: -0.7rem;
            top: -0.2rem;
          }
        }
      }

      .block-title {
        max-width: 529px;
        width: 100%;
      }

      .field {
        position: relative;
        margin: 3rem 0 0;
        max-width: 529px;
        width: 100%;

        .label {
          font-weight: 700;
          line-height: 2.4rem;
          position: absolute;
          left: 12px;
          top: -11px;
          background: $white;
          padding: 0 0.8rem;
          font-size: 1.3rem;
          z-index: 3;
        }

        @extend %input-svg-icon;
      }

      .toolbar-wrapper {
        max-width: 529px;
        width: 100%;

        .actions-toolbar {
          float: right;
          margin-top: 2.9rem;

          button {
            letter-spacing: 0;
            padding: 0.8rem 3.5rem;
            border: 1px solid $primary;
            border-radius: 2px;
            line-height: 2.2rem;

            span {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.customer-address-index {

  .column.main {
    @extend %account-section-title;

    .block-addresses-default,
    .block-addresses-list {
      @extend %account-address-block-content;

      .block-title {
        margin-bottom: 0 !important;
      }
    }
  }
}
