.panel.wrapper {
  border-bottom: none !important;
  background-color: $white !important;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .topbar {
    max-width: 1310px;
    margin: 0 auto;
    position: relative;
    display: flex;

    &::after {
      content: '';
      height: 100%;
      width: calc((100vw - 1310px) / 2);
      background-color: #980d19;
      position: absolute;
      top: 0;
      left: 100%;
    }
  }

}

.page-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  border-bottom: none;
  margin-bottom: 0;
}

.header-content-container {
  background: linear-gradient(to bottom, map-get($grays, '900'), map-get($grays, '800'));


  .aa-Form {
    background: $body-bg;
    border-radius: 5px;

    .aa-InputWrapperPrefix {
      position: absolute;
      height: 0;
      top: 15px !important;
      right: 17px !important;

      .aa-SubmitButton {
        position: relative !important;
        height: 20px !important;
        background: url('../images/magnify-icon.svg') no-repeat center right / 24px !important;

        svg {
            display: none;
          }
      }
    }

    .aa-InputWrapper {
      border-radius: 5px;

      .aa-Input {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        display: block;
        width: 100%;
        height: calc(1.5em + 2.7rem + 2px);
        padding: 1.35rem 2rem;
        font-weight: 500;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ccc;
        font-size: 16px;
        border-radius: 0.3rem;
        -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
      }

      @include media-breakpoint-down(md) {

        .aa-Input {
          margin: 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {

      .aa-Input {
        height: 40px;
      }

      .aa-InputWrapperPrefix {
        bottom: 10px !important;
      }
    }
  }

  .header.content {
    padding: 0;
    height: 136px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 1310px;

    @include media-breakpoint-up(lg) {
      padding: 0 1.5rem;
      height: 90px;
    }

    a.back-to-cart-link,
    a.keep-shopping-link {
      display: none;
    }

    .logo {
      margin: 0;
      flex: 3 1 0;

      @include media-breakpoint-up(lg) {
        max-width: 270px;
        width: 100%;
        margin-top: 2.4rem;
      }
    }

    .block-search {
      float: left;
      flex: 5 1 0;

      .aa-Form {
        position: relative;

        .aa-Input:focus {
          outline: 0;
          box-shadow: 0 0 0 5px $primary;
        }

        @include media-breakpoint-up(xl) {
          margin-left: 7rem;
        }


        @include media-breakpoint-up(lg) {
          max-width: 600px;
          width: 100%;
          float: none;
          margin-left: 4rem;
        }
      }
    }

    .header-links {
      flex: 3 1 0;

      .header.links {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding: 0;
        position: relative;

        @include media-breakpoint-down(md) {
          margin: 0;
        }

        li {
          padding: 0 2.2rem;
          margin: 0;
          text-align: center;
          display: flex;

          >button {
            background: none;
            border: none;
            padding: 0;

            &:hover,
            &.active,
            &.focus {
              background: none;
              border: none;
              box-shadow: none;
            }
          }

          .counter {
            position: absolute;
            top: -10px;
            right: 8px;
            border-radius: 2rem;
            width: 24px;
            background: $yellow;
          }

          @include media-breakpoint-down(md) {
            padding: 0 1.3rem;
          }
        }

        li.header-link-account {
          border-right: 2px solid map-get($grays, '800');
        }

        li {
          background: none;

          > button svg {
            width: 48px;
            height: 48px;

            @include media-breakpoint-down(xs) {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }



    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      max-width: 992px;

      .block-search {
        .aa-Input {
          left: 0;
          position: static;
        }
      }

      .header-links .header-actions .header.links {
        justify-content: flex-end;
      }
    }

    @include media-breakpoint-down(md) {
      align-items: flex-start;

      .block-search {
        order: 4;
        flex: none;
        width: 95%;
        padding: 0;
      }

      #navToggle {
        background: linear-gradient(to bottom, #1d1d1d, #343434);
        margin: 0;
        border: none;
        box-shadow: none;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .logo {
        flex-grow: 4;
        height: 68px;
        background: linear-gradient(to bottom, #1d1d1d, #343434);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 26px;
          margin-top: 0.4rem;
        }
      }

      .block-search {
        margin: 0;

        .search {

          .control {
            border-top: none;
            margin: 0;

            .aa-Input {
              height: 40px;
              font-size: 1.4rem;
              background-position: right 1.7rem top 0.7rem !important;
              margin: 0.5rem 0;
            }
          }

          .label {
            display: none;
          }
        }
      }

      .header-links {
        height: 68px;
        display: flex;
        justify-content: flex-end;
        background: linear-gradient(to bottom, #1d1d1d, #343434);

        .header-actions {
          display: flex;
          align-content: center;

          @include media-breakpoint-down(md) {

            .header-link-account {
              svg {
                width: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}

.minicart-wrapper .svg-container svg {

  @include media-breakpoint-down(md) {
    height: 24px;
    width: auto;
  }
}
