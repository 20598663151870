.catalog-product-view {

  .page-wrapper {
    overflow: visible;

    .page-main {
      max-width: 1186px;
      padding: 0;

      .columns .column {

        .product-price-title,
        .product.media {
          padding-top: 2.4rem;
        }

        @include media-breakpoint-down(sm) {

          .product.media {
            padding-top: 1.5rem;
          }
        }

        @include media-breakpoint-down(md) {

          .product.media {
            order: 0;
          }

          .product-price-title {
            order: -1;
          }
        }
      }
    }
  }
}

.product.media {

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  .gallery-placeholder {

    @include media-breakpoint-up(md) {
      padding-left: 2rem;
    }
  }

  .fotorama__thumb-border {
    border: 1px solid $gray-400;
  }

  .fotorama__stage {
    width: 555px !important;
    height: 460px !important;

    @include media-breakpoint-down(md) {
      max-width: 270px !important;
    }

    @include media-breakpoint-down(sm) {
      left: 0 !important;
    }
  }

  .fotorama__nav::after {
    box-shadow: none;
    background: $white;
  }

  @include media-breakpoint-down(lg) {

    .fotorama__stage {
      left: 0 !important;
    }

    .fotorama__nav {

      .fotorama__nav__shaft {
        display: none;
      }
    }
  }

  .fotorama__stage__shaft {
    max-width: 460px !important;

    @include media-breakpoint-down(md) {
      max-width: 270px !important;
    }

    .fotorama__stage__frame {
      background: $white;
    }
  }

  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    display: block !important;
  }
}

.product.data.items {
  margin: 0;
}

.product-price-title,
.product-info-main,
.product.media,
.product-info-extra {
  width: 50% !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.product-price-title,
.product-info-main {
  padding-right: 2rem;
}

.fotorama__wrap,
.fotorama__stage {
  height: 100% !important;

  @include media-breakpoint-down(sm) {
    max-height: 270px;
    max-width: 270px;
  }
}

.fotorama__wrap {

  .fotorama__thumb {
    background: $body-bg;
  }

  .video-thumb-icon::after {
    top: 50%;
    right: 15px;
    margin-top: 15px;
    transform: translateY(-50%);
  }
}

.product-price-title {

  @include media-breakpoint-up(md) {
    float: right;
  }

  .page-title-wrapper {

    .page-title {
      font-size: 3.5rem;
      font-family: $headings-font-family;
      margin-bottom: 2.2rem;

      @include media-breakpoint-down(md) {
        font-size: 2.5rem;
      }
    }
  }

  .product.attribute.overview {
    margin-bottom: 1.2rem;

    .mgz-element-column>.mgz-element-inner {
      padding: 0;
    }
  }

  .product-info-stock-sku {
    padding: 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1.2rem;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.8rem;
    }

    .configurable-variation-qty,
    .availability.only {
      margin-right: 1rem;
    }

    .product_label {
      order: 0;

      &.on_sale {
        background: $primary;

        &::after {
          border-color: $primary transparent transparent;
        }
      }

      &.call_for_price {
        background: #ffa439;

        &::after {
          border-color: #ffa439 transparent transparent;
        }
      }

      &.clearance {
        background: $blue;

        &::after {
          border-color: $blue transparent transparent;
        }
      }

      color: $body-bg;
      font-size: 1rem;
      font-weight: 700;
      display: table;
      height: 20px;
      margin-right: 1.4rem;

      span {
        padding: 0.3rem 1.1rem;
        display: table-cell;
        vertical-align: middle;
        white-space: nowrap;
      }

      &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 2rem 0.9rem 0 0;
        position: absolute;
      }
    }

    .stock {
      color: #00ad75;
      text-transform: none;
      order: 2;
      font-size: 1.2rem;
      font-weight: 700;

      &.unavailable {
        color: $primary;
      }

      span {
        vertical-align: middle;
      }
    }

    .product.attribute.sku {
      color: map-get($grays, '600');
      font-size: 1.2rem;
      margin-right: 1.6rem;
      display: flex;
      align-items: center;
      order: 1;

      .type,
      .value {
        vertical-align: middle;
      }

      .type {
        margin-right: 0.4rem;
        font-weight: 700;

        .value {
          white-space: nowrap;
        }

        &::after {
          content: ':';
        }
      }
    }

    .product.attribute.brand {
      width: 100%;
      order: 1;
    }

    .product.attribute.pack-size {
      width: 100%;
      order: 3;
    }
  }

  .price-wrapper {
    span {
      font-family: $headings-font-family;
      vertical-align: middle;
      font-weight: 700;
    }

    .single-units {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      font-size: 2.5rem;
      color: #000;

      .price-box {
        min-height: unset;

        .price-final_price .price {
          font-size: 3rem;

          @include media-breakpoint-down(md) {
            font-size: 2.2rem;
          }
        }
      }

      .units {
        font-size: 2.2rem;

        @include media-breakpoint-down(md) {
          font-size: 1.9rem;
        }
      }
    }

    .bulk-price {
      span {
        font-size: 3.5rem;
        color: $black;

        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
        }

        &.units {
          font-size: 2.8rem;

          @include media-breakpoint-down(md) {
            font-size: 2.1rem;
          }
        }
      }
    }
  }

  .price-box {
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up (md) {
      min-height: 7.3rem;
    }

    .special-price,
    .price-final_price {

      .price {
        font-size: 3.5rem;
        color: $black;
        font-family: $headings-font-family;

        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
        }
      }
    }

    .old-price .price-final_price {

      .price {
        font-size: 1.4rem;
        color: map-get($grays, '600');
        text-decoration: line-through;
        font-weight: 400;
        font-family: inherit;
      }

      @include media-breakpoint-up(md) {

        .price-label {
          display: none;
        }
      }
    }
  }
}

.product-info-extra {
  padding: 2rem 2rem 0;

  @include media-breakpoint-up(md) {
    float: right;
    padding: 2rem 0 0;
  }

  .finapps-link {
    font-size: 1.6rem;
    line-height: normal;
    font-weight: 700;
    padding: 0;
    background: none;
    border: none;
    color: $primary;
    text-decoration: underline;
  }
}

.product-page-view-request-outofstock .product-request-form {
  margin-top: 1.6rem;
}

.product-request-form {
  float: right;
  max-width: calc(50% - 2.3rem);
  width: 100%;
  border: 3px solid $black;
  border-radius: 3px;
  margin-bottom: 6rem;
  margin-right: 2.3rem;
  margin-top: 3.5rem;

  .form-header {
    background-color: $primary;
    color: $white;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.33;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    text-align: center;
    display: flex;
    justify-content: center;

    span {
      display: block;
      padding-left: 3.4rem;
      position: relative;
    }

    span::before {
      content: '';
      background-image: url('../images/icon-phone.svg');
      background-size: 24px 24px;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
    }
  }

  .form-header.out-of-stock-header {

    span {
      padding-left: 0;
    }

    span::before {
      content: '';
      background-image: none;
    }
  }

  .product-form.out-of-stock {
    padding-top: 2rem;
    text-align: center;
  }

  .form-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    a {
      display: block;
      text-align: center;
      font-weight: 700;
      font-size: 2.4rem;
      font-family: $headings-font-family;
      padding: 1.3rem 0;
    }

    a:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .form.contact.request {
    padding: 2.2rem 4rem 3.4rem;

    .field {
      position: relative;

      label {
        position: absolute;
        left: 12px;
        top: -9px;
        background: $white;
        padding: 0 0.8rem;
        font-size: 1.3rem;
        z-index: 1;

        &::after {
          color: $required-input-label !important;
        }
      }

      .control {

        input[name=firstname],
        input[name=lastname] {
          padding: 1.35rem 2rem;
        }

        input {
          padding: 1.35rem 2rem 1.35rem 4.7rem;
          color: $black;

          &:focus {
            box-shadow: $btn-box-shadow-bottom;
            border-color: map-get($grays, '800');
            opacity: 1;
          }
        }

        span {
          position: absolute;
          top: 11px;
          left: 19px;
        }
      }
    }

    .actions-toolbar {
      display: flex;
      justify-content: center;

      button {
        border: 1px solid $black;
        box-shadow: $btn-box-shadow-bottom;
        font-size: 1.5rem;
        font-weight: 700;
        color: map-get($grays, '800');
      }

      button:hover {
        background-color: map-get($grays, '800');
        color: $white;
      }
    }

    fieldset {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: calc(100% - 4rem);
    margin: 0 2rem;
  }
}

.product-info-main {

  .product-add-form {
    padding-top: 0.5rem;

    form {
      display: flex;
      flex-direction: row;

      @include media-breakpoint-down(md) {
        justify-content: center;
        margin-top: 1.8rem;
      }

      .qty.field {
        margin-bottom: 0;

        .label {
          display: none;
        }

        .increment.control {
          display: flex;
          border-radius: 0;

          button {
            background-color: $dark;
            border: 2px solid $dark;
            line-height: 1;
            color: $white;
            font-weight: normal;
            width: 50px;

            .minus-icon {
              font-size: 3rem;
            }

            .plus-icon {
              font-size: 2rem;
            }

            &.product {
              &-increaseQty {
                border-left: unset;
              }

              &-decreaseQty {
                border-right: unset;
              }
            }
          }

          input {
            width: 62px;
            padding: 1.35rem 1.5rem 1.35rem 1rem;
            border: unset;
            text-align: right;
          }

          .units {
            display: flex;
            align-items: center;
            padding-right: 2rem;
            font-size: 2.2rem;
          }

          div.mage-error {
            display: none !important;
          }
        }

        input {
          border-radius: 0;
          border: 2px solid $primary;
          text-align: center;
          height: 50px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          width: 120px;
          font-size: 2.4rem;
          color: $black;

          @include media-breakpoint-down(md) {
            width: 80px;
          }

          &:hover {
            box-shadow: none;
          }
        }

        .mage-error {
          width: 120px;
        }
      }

      .actions {
        width: 391px;

        button {
          height: 50px;
          margin: 0;
          width: 100%;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          font-size: 1.5rem;
          font-weight: 500;
          font-family: $headings-font-family;
          border-left: 0;
          box-shadow: 0 3px 6px #00000036;

          &:hover {
            border-width: 2px !important;
          }
        }
      }
    }

    .product.pricing {
      line-height: 2.5;
      font-size: 1.3rem;
    }
  }
}

// packed products
.page-product-configurable {

  .product-info-main {

    .product-add-form {
      padding-top: 0.5rem;

      .action {

        button {
          margin-top: 2.9rem;
        }
      }

      .qty.field,
      .field.configurable {
        margin-bottom: 0;

        .label {
          display: block;
          font-size: 1.4rem;
          font-weight: 700;
          color: $primary;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }

      .qty.field {
        order: -1;
      }

      .product-options-wrapper {
        margin: 0 0.7rem;

        @include media-breakpoint-down(md) {
          margin: 0;
        }

        .fieldset {
          margin: 0;

          .field.configurable {

            .control {
              border-radius: 0;
              border: 2px solid $primary;
              text-align: center;
              height: 50px;
              border-radius: 2px;
              width: 125px;
              font-size: 2.4rem;
              color: $black;

              @include media-breakpoint-down(md) {
                border-right: none;
                border-left: none;
              }

              select {
                height: auto;
                border: none;
                background-position: right 0.3rem center;
                width: 120px;
                text-align-last: center;

                &:focus,
                &:hover {
                  outline: none;
                }
              }

              .mage-error {
                white-space: nowrap;
                padding-top: 0.5rem;
              }
            }
          }
        }
      }

      .actions {
        margin-top: 2.1rem;
        max-width: 232px;

        button {
          border-left: 2px solid $primary;
        }
      }
    }
  }

  .product-price-title {

    @include media-breakpoint-up(md) {

      .price-box {
        min-height: 7.3rem;

        .price-label {
          display: none;
        }
      }
    }
  }
}

// product info tabs
.product.info.detailed {
  padding: 0 2rem 8.4rem;
  max-width: 903px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    padding: 8.4rem 2rem 0;
  }

  .product.data.items {
    border-bottom: none;

    .product-details-tab-wrapper {
      display: flex;
      flex-direction: row;

      .product-details-tab {
        flex: 1 1 auto;

        @include media-breakpoint-up(md) {

          .content {
            display: none !important;
          }
        }

        .data.item.title {
          text-align: center;
          border-bottom: $gray-400 1px solid;
          padding: 1.1rem 0;
          cursor: pointer;

          &.active {
            border-bottom: $primary 3px solid;
          }

          &:not(:last-child) {
            margin-right: 1.2rem;
          }

          button {
            font-size: 1.8rem;
            line-height: 1.8rem;
            font-family: $headings-font-family;
            color: black;
            font-weight: 500;
            text-transform: uppercase;
            border: none;
            background: $white;
            box-shadow: none;
            padding: 0;
          }
        }
      }
    }

    .product.attribute.description {
      .mgz-element-column>.mgz-element-inner {
        padding: 10px 0;
      }

      ul {
        padding-inline-start: 20px;
      }

    }

    .data.item.content {
      padding: 0;
      border: none;

      .description ul, .description u {
        text-decoration: none;
      }

      .download-pdf-wrapper .pdf-name {

        &,
        &:hover {
          color: $black;
        }
      }

      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    @include media-breakpoint-down(sm) {

      .product-details-tab-wrapper {
        flex-direction: column;

        .product-details-tab {

          .data.item.title {
            text-align: left;
            margin: 0;
            padding: 1.3rem 0 1.3rem 1.4rem;
            background-image: url(../images/plus-black.svg);
            background-size: 1.5rem 1.5rem;
            background-position: right top 1.5rem;
            background-repeat: no-repeat;

            &.active {
              border-bottom: $gray-400 1px solid !important;
            }

            &[aria-expanded=true] {
              background-image: url(../images/minus-black.svg);
            }
          }

          .content {
            padding: 3rem 1.6rem 0;
          }
        }
      }
    }

    .additional-attributes {
      margin: 0;
      width: 100%;

      tbody {
        width: 100%;
        display: block;

        tr {
          display: flex;

          > th {
            max-width: 22%;
          }

          > th, > td {
            padding: 15px 20px;
            line-height: 1;
            font-size: 1.4rem;
          }

          &:nth-child(odd) {
            background-color: $drop-down-gray;
          }
        }
      }
    }
  }
}

// related products
.block.related {
  padding: 0 2rem;

  .block-title.title {
    font-family: $headings-font-family;
    color: $black;
    text-align: center;
    margin-bottom: 1.6rem;

    strong {
      font-size: 2.5rem;
    }
  }

  .block-content.content {

    .products {
      margin: 0 0 7.2rem;
      max-width: 1150px;

      .product-item {

        .product-item-info {
          position: relative;
          width: 200px !important;
          margin: 0 auto;

          @include media-breakpoint-down(sm) {
            width: 160px !important;
          }

          .product_label {
            color: $body-bg;
            position: absolute;
            top: 2.7rem;
            left: 0;
            z-index: 9;
            display: table;

            @extend %product_label;
          }

          &:hover,
          &:active {
            box-shadow: none;
            padding: 0;
            z-index: 8;
            border: none;
            margin: 0 auto;
          }

          .product-item-details {

            .product-item-name {
              margin: 0.5rem 0;
              height: 40px;

              .product-item-link {
                text-decoration: none;
                color: $black;
              }
            }

            .price-box {
              display: flex;
              flex-direction: column-reverse;
              margin: 1rem 0 2rem;
              height: 53px;

              .price-final_price .price {
                color: $black;
                font-size: 1.8rem;
              }

              .old-price {

                .price,
                .price-label {
                  text-decoration: line-through;
                  color: $old-price-gray !important;
                  font-size: 1.2rem !important;
                }
              }
            }

            .product-item-actions {

              .actions-primary {
                width: 100%;

                @extend %product_button_call_for_price;

                button {
                  width: 100%;
                  padding: 0.7rem 7rem;
                  background: $gray-800;
                  border: 1px solid $gray-800;
                  border-radius: 2px;
                  font-size: 1.2rem;
                  line-height: 1.2rem;
                  box-shadow: 0 3px 6px #00000036;

                  &:hover,
                  &:focus {
                    background: $white;
                    color: $gray-800;
                    box-shadow: none;
                  }
                }
              }
            }
          }

          .product-image-container {
            width: 100% !important;

            .product-image-wrapper {
              padding-bottom: 100% !important;
            }
          }
        }
      }

      .slick-dots {
        display: flex;
        flex-direction: row;

        li {
          flex: 1 1 auto;
          margin: 0;

          &:first-child {
            margin-left: 1.5rem;
          }

          &:last-child {
            margin-right: 1.5rem;
          }

          button {
            width: 100%;
            background: $gallery-nav-gray;
            height: 3px;
            padding: 0;
            margin-top: 0;

            @include media-breakpoint-down(sm) {
              margin-top: 3.8rem;
            }

            &::before {
              display: none;
            }
          }
        }

        li.slick-active {

          button {
            background: $gray-800;
          }
        }
      }

      .slick-arrow {
        display: none !important;
      }
    }
  }
}
