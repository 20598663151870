.cms-about-us {

  .page-main {
    margin-top: 3rem;

    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }
  }

  .section-1,
  .section-2 {

    strong {
      color: $primary;
    }
  }

  .brand-list {

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;

      li {
        width: calc(100% / 8);
        list-style-type: none;

        @include media-breakpoint-down(sm) {
          width: calc(100% / 4);
        }

        @include media-breakpoint-down(xs) {
          width: calc(100% / 2);
        }
      }
    }
  }
}