/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

 @import 'bootstrap/functions';
 @import 'bootstrap/variables';
 @import 'bootstrap/mixins';
 @import 'bootstrap/root';
 @import 'bootstrap/reboot';
 @import 'bootstrap/type';
 @import 'bootstrap/images';
//  @import 'bootstrap/code';
 @import 'bootstrap/grid';
 @import 'bootstrap/tables';
 @import 'bootstrap/forms';
 @import 'bootstrap/buttons';
 @import 'bootstrap/transitions';
 @import 'bootstrap/dropdown';
 @import 'bootstrap/button-group';
 @import 'bootstrap/input-group';
 @import 'bootstrap/custom-forms';
 @import 'bootstrap/nav';
//  @import 'bootstrap/navbar';
 @import 'bootstrap/card';
 @import 'bootstrap/breadcrumb';
 @import 'bootstrap/pagination';
 @import 'bootstrap/badge';
//  @import 'bootstrap/jumbotron';
 @import 'bootstrap/alert';
//  @import 'bootstrap/progress';
 @import 'bootstrap/media';
 @import 'bootstrap/list-group';
 @import 'bootstrap/close';
//  @import 'bootstrap/toasts';
//  @import 'bootstrap/modal';
 @import 'bootstrap/tooltip';
//  @import 'bootstrap/popover';
//  @import 'bootstrap/carousel';
 @import 'bootstrap/spinners';
 @import 'bootstrap/utilities';
//  @import 'bootstrap/print';
 