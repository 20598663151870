#minicart-content-wrapper {

  .block-content {
    display: flex;
    flex-direction: column;

    .subtotal {
      order: 3;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0;

      &,
      .price-container .price,
      .label,
      .label::after {
        font-size: 2.2rem;
        font-weight: 700;
      }

      .label::after {
        margin-left: -0.5rem;
        margin-right: 0.4rem;
      }
    }

    .actions {
      order: 4;
      padding: 0 3rem;
      margin: 0;

      // checkout button
      .primary {
        margin: 2.6rem 0 0;
        box-shadow: $box-shadow-sm;
        opacity: 1;

        button {
          padding: 0.8rem 1.7rem;
          font-size: 1.5rem;
          font-weight: 500;
        }
      }

      // view cart button
      .secondary {
        margin-top: 0.9rem;
        border: 1px solid $gray-800;
        border-radius: 2px;

        .action.viewcart span {
          color: $gray-800;
          vertical-align: middle;
          padding: 0.7rem;
          text-decoration: none;
          font-size: 1.5rem;
          font-weight: 500;
          font-family: $headings-font-family;
          display: block;
          background: $white;
          text-transform: uppercase;
          border: none;
        }

        &:hover {

          .action.viewcart span {
            color: $white;
            background: $gray-800;
          }
        }
      }
    }

    .items-total {
      display: none;
    }

    .minicart-widgets {
      margin: 0;
      order: 5;
    }

    .payment-method-icons {
      order: 6;
      margin-top: 1.8rem;

      svg {
        height: 27px;
      }
    }

    .minicart-items-wrapper {
      flex-grow: 1;
      margin: 0;
      padding: 1.3rem 3rem 0;
      border: none;
      height: auto !important;

      li.product-item {
        flex-wrap: wrap;
        width: 100%;
        padding: 2rem 0;

        .product {
          width: 100%;

          .product-item-photo {
            margin-right: 1rem;

            .product-image-container {
              width: 60px !important;
              height: 60px !important;
            }
          }

          .product-item-details {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 0;

            .product-item-name {
              order: 0;
              text-align: left;

              a {
                color: $black;
                text-decoration: none;
                font-size: 1.3rem;
                font-weight: 400;
              }
            }

            .product-item-pricing {
              order: -1;

              .price-excluding-tax {
                margin: 0 0 0.6rem;

                .price {
                  font-size: 1.5rem;
                }
              }
            }

            .details-qty {
              order: 2;
              margin-top: 0;

              .cart-item-qty {
                border: none;
                width: 3.5rem;
                font-size: 1.3rem;
                font-weight: 700;
              }

              .units {
                font-weight: 700;
                margin-left: -.6rem;
                padding-right: 1rem;
              }

              .qty-plus,
              .qty-minus {
                background: $white;
                border: none;
                width: 24px;
                height: 24px;
                padding: 0;
              }

              .update-cart-item {
                border-radius: 3px;
                background: $white;
                padding: 0.4rem 1.5rem;
                margin-left: 2rem;
              }
            }

            .product.options .list {

              .label {
                margin: 0 1rem 0 0;
                font-size: 1.2rem;
              }

              .values {
                margin: 0;
                font-size: 1.2rem;
              }
            }

            .product {
              width: 10rem;
            }

            .product.actions {
              margin-top: -2.2rem;
              margin-left: 10rem;

              .primary {
                display: none;
              }

              .secondary {

                .action.delete {

                  >span {
                    position: relative;
                    padding: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
