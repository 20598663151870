.catalog-category-view,
.catalogsearch-result-index {

  .page-main {
    max-width: 1366px;

    .page-title-wrapper {
      text-align: center;
      margin: 2.6rem auto 1.1rem;
    }

    .category-view {
      position: relative;
      max-width: 980px;
      margin: 0 auto 3.5rem;
      
      .category-image img{
        margin: 0 auto;
      }
      
      .subcategory-wrapper {
        margin-top: 4.5rem;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {

          &::before,
          &::after {
            content: '';
            display: table;
            clear: both;
          }
        }
      }

      @include media-breakpoint-down(md) {

        .show-text {
          height: 81px;
          overflow: hidden;
          display: inline-block;
        }
      }

      .hide-text {

        &.show ~ .read-more-trnas,
        &.collapsing ~ .read-more-trnas {
          display: none;
        }
      }

      .btn-wrapper {
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 2;
        background: $white;
        margin-top: 1.4rem;

        .read-more-btn {
          @extend %read-more-btn;
        }
      }

      .read-more-trnas {
        position: absolute;
        bottom: 45px;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 4.1rem 0 0;
        background-image: linear-gradient(to bottom, $transparent-white, $white);
        z-index: 1;
      }
    }
  }

  .algolia-container-wrapper {
    justify-content: space-around;

    &::after,
    &::before {
      display: none !important;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .algolia-right-container {
    max-width: 913px;
    width: 100% !important;
    padding: 0;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    .subcategory-wrapper {

      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }
    }

    .subcategory-right {
      padding-left: 0;

      ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        flex-wrap: wrap;

        li {
          margin-right: 1rem;

          a {
            box-shadow: $btn-box-shadow-bottom;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            &,
            &:active,
            &:focus {
              color: $white !important;
            }
          }
        }

        li:last-child {
          margin-right: 0;
        }

        li:hover a {
          color: $primary !important;
        }
      }
    }

    .hits {
      position: relative;
    }

    .algolia-infos {
      padding: 0;
      background: $drop-down-gray;
      line-height: 1.5;

      @include media-breakpoint-down(md) {
        margin: 0 -1.5rem;
        background: $white;
      }

      .sortby-container {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
          width: 50%;
          justify-content: center;
        }

        .sort-by-label {
          padding: 1.5rem 1.6rem 1.5rem 2.8rem;
          color: $black;
          font-size: 1.3rem;
          font-weight: 700;
          line-height: 1;
          white-space: nowrap;
          flex-grow: 1;

          @include media-breakpoint-down(xs) {
            display: none;
          }
        }

        .ais-SortBy {
          padding: 0.8rem 0;
          flex-grow: 1;

          .ais-SortBy-select {
            background-position: right 0.4rem center;
            background-color: $drop-down-gray;
            border-color: $drop-down-gray;
            padding-right: 3rem;
          }
        }
      }

      .algolia-sorts {
        max-width: 130px;
        width: 100%;
      }

      .product-per-page {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 3.3rem 0.8rem 0;
        font-size: 1.3rem;
        color: $item-per-page-gray;

        @include media-breakpoint-down(xs) {
          width: 50%;
          justify-content: center;
          padding: 0.8rem 0;
        }

        .hitsPerPage {
          width: 100px;

          .HitsPerPageSelect {
            padding: 0 1rem;
          }
        }

        .display-per-page {
          font-weight: 700;
          color: $black;
          margin-right: 1.6rem;
        }
      }
    }

    .instant-search-bar {
      display: none;
    }

    .instant-search-results-container {
      // product list
      .ais-Hits,
      .ais-InfiniteHits {
        padding-top: 0;
        padding-bottom: 0;

        .ais-InfiniteHits-loadPrevious {
          display: none;
        }
      }

      .ais-Hits-list,
      .ais-InfiniteHits-list {
        display: flex;
        flex-wrap: wrap;

        .ais-Hits-item,
        .ais-InfiniteHits-item {
          margin: 0;
          padding: 0;
          width: 25%;
          float: left;

          @include media-breakpoint-up(xl) {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              border-top: 0;
            }

            &:nth-child(4n) {
              border-right: 0;
            }

            &:nth-child(4n+1) {
              border-left: 0;
            }
          }

          @include media-breakpoint-only(lg) {
            width: 33%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              border-top: 0;
            }

            &:nth-child(3n) {
              border-right: 0;
            }

            &:nth-child(3n+1) {
              border-left: 0;
            }
          }

          @include media-breakpoint-only(md) {
            width: 33%;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              border-top: 0;
            }

            &:nth-child(3n) {
              border-right: 0;
            }

            &:nth-child(3n+1) {
              border-left: 0;
            }
          }

          @include media-breakpoint-down(sm) {
            width: 50%;

            &:nth-child(1),
            &:nth-child(2) {
              border-top: 0;
            }

            &:nth-child(2n) {
              border-right: 0;
            }

            &:nth-child(2n+1) {
              border-left: 0;
            }
          }

          .product-item {
            width: 200px;
            margin: 0 auto;
            height: 100%;

            @include media-breakpoint-down(xs) {
              width: 160px;
            }
          }

          .result-wrapper {
            padding: 2rem 0 5.2rem;
            height: 100%;

            .result-content {
              position: relative;
              height: 100%;
              display: flex;
              flex-direction: column;

              .product_label {
                color: $body-bg;
                position: absolute;
                top: 2.3rem;
                left: 0;
                z-index: 3;
                display: table;

                @extend %product_label;
              }

              // product image
              .result-thumbnail {

                img {
                  width: 200px;
                  height: 200px;

                  @include media-breakpoint-down(xs) {
                    width: 160px;
                    height: 160px;
                  }
                }
              }

              // product description
              .result-sub-content {
                display: flex;
                flex-direction: column;
                height: 100%;

                a.result {
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                }

                .product-item-name {
                  flex-grow: 1;
                  margin: 0;

                  .result-title {
                    font-size: 1.4rem;
                    color: $black;
                    text-transform: none;
                    font-family: $font-family-sans-serif;
                    margin: 0;
                  }
                }

                .product-item-sku {
                  font-size: 1.3rem;
                  color: $black;
                  text-align: left;
                }

                .ratings {

                  .product-reviews-summary {
                    display: none;
                  }

                  .price-wrapper {
                    min-height: 53px;
                    margin: 1rem 0 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    > div {
                      display: flex;
                      flex-direction: column-reverse;

                      .after_special {
                        font-size: 1.8rem;
                        color: $black;
                        text-align: left;
                        font-weight: 700;
                      }

                      .before_special {
                        color: $old-price-gray;
                        text-decoration: line-through;
                        font-size: 1.4rem;
                        text-align: left;
                      }
                    }
                  }
                }

                .product-item-actions {

                  .actions-primary {
                    width: 100%;

                    @extend %product_button_call_for_price;
                  }

                  .action.primary {
                    width: 100%;
                    height: 30px;
                    font-size: 1.2rem;
                    font-family: $headings-font-family;
                    background: $gray-800;
                    border-color: $gray-800;
                    margin: 0;
                    letter-spacing: 0;

                    &:hover {
                      background: $white;
                      color: $gray-800;
                    }

                    &:focus {
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ais-InfiniteHits-loadMore {
        @extend %read-more-btn;

        background-color: $white;
        border-color: $gray-800;
        text-align: center;
        margin: 8.5rem auto 11.5rem;
      }

      .ais-InfiniteHits-loadMore--disabled {
        display: block !important;
        visibility: hidden;
      }
    }

    .algolia-stats {
      position: absolute;
      width: 100%;
      bottom: 55px;

      .page-stats {
        margin: 0 auto;

        &.hidden {
          display: none;
        }

        .dot-wrapper {
          max-width: 460px;
          display: flex;
          margin: 0 auto;

          @include media-breakpoint-down(xs) {
            max-width: 350px;
          }

          .dot {
            flex: 1 1 auto;
            width: 100%;
            background: $gallery-nav-gray;
            height: 0.3rem;
            margin: 0.8rem 0;

            &.active {
              background: $gray-800;
            }
          }
        }

        .page-num {
          text-align: center;
          font-size: 1.3rem;
          color: $black;
        }
      }
    }
  }

  .algolia-left-container {

    @include media-breakpoint-down(md) {
      max-width: 100%;
      width: 100% !important;
    }

    .refine-toggle {
      display: block;
      margin: 0 !important;
      background: $gray-800;
      color: $white;
      font-size: 1.8rem;
      font-family: $headings-font-family;
      line-height: 2.4rem !important;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1.2rem 1.3rem;
      border: none !important;
      text-align: left !important;

      @include media-breakpoint-down (md) {
        text-align: center !important;
        padding: 0.7rem 1.3rem;
        margin: 0 -1.5rem !important;
      }
    }

    .instant-search-facets-container {

      @include media-breakpoint-only(md) {
        display: none;

        &.show {
          display: block;
        }
      }

      @include media-breakpoint-only(sm) {
        display: none;

        &.show {
          display: block;
        }
      }

      @include media-breakpoint-down(xs) {
        position: fixed;
        top: 0;
        display: block;
        margin: 0;
        max-width: 575px;
        min-width: 0;
        width: 100%;
        height: 100%;
        transition: transform 0.3s;
        left: auto;
        right: 0;
        transform: translateX(100%);
        z-index: 100;
        background: white;

        &.show {
          left: auto;
          padding: 0 2rem;
          transform: translateX(0);
          box-shadow: $box-shadow-lg;
          overflow: scroll;
          box-shadow: none;

          .filter-header-mb {
            display: block;
          }
        }
      }

      .filter-header-mb {
        margin-right: -2rem;
        margin-left: -2rem;
        display: none;
        position: relative;
        background: $gray-800;
        color: $white;
        font-size: 2rem;
        text-transform: uppercase;
        font-family: $headings-font-family;
        font-weight: 500;
        text-align: center;
        padding: 0.8rem 2rem;

        button {
          margin-top: 0.5rem;

          .svg {
            width: 2.5rem;
            opacity: 0.75;
            position: absolute;
          }
        }
      }

      .clear-refinements {
        margin: 1rem 0;

        .ais-ClearRefinements-button {
          padding: 0.5rem;
          max-width: 10rem;
          letter-spacing: 0;
        }
      }

      .ais-CurrentRefinements-list {
        margin: 1rem 0;

        .ais-CurrentRefinements-delete {
          margin-bottom: 0.4rem;
          position: relative;
          visibility: hidden;

          &::after {
            position: absolute;
            content: '';
            background-image: url('../images/close.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            height: 24px;
            width: 16px;
            left: 0;
            top: -1px;
            visibility: initial;
            margin-left: 0.3rem;
          }
        }
      }

      .filter-widget-container {

        .ais-Panel {
          border: none;
        }

        .ais-Panel-header {
          padding: 0;
          background: $white;
          color: $black;
          border-bottom: 1px solid $gray-500;

          .ais-Panel-collapseButton {
            font-family: $headings-font-family;
            font-size: 1.8rem;
            padding: 1.1rem 1.3rem;
            background: $white;
            font-weight: 500;
            line-height: 2.6rem;
            text-transform: uppercase;
            width: 100%;
            border: none;
            text-align: left;
            background-image: url('../images/category-filter-plus.svg');
            background-size: 0.8rem 0.8rem;
            background-position: right 1.1rem top 2rem;
            background-repeat: no-repeat;

            &[aria-expanded=true] {
              background-image: url('../images/category-filter-minus.svg');
            }

            &:active {
              box-shadow: none;
            }
          }
        }

        .ais-Panel-body {
          padding: 0;

          // Search box
          .ais-RefinementList-searchBox {
            display: none;
          }

          // Brands
          .ais-RefinementList-list {
            margin-top: 1.4rem;

            .ais-RefinementList-item {
              cursor: pointer;

              .ais-RefinementList-checkbox {
                height: 0;
                width: 0;
                display: none;
              }

              .ais-RefinementList-label {
                font-size: 1.3rem;
                display: flex;
                align-items: center;
                margin: 0 0 2rem 1.4rem;

                .checkbox-label {
                  width: 24px;
                  height: 24px;
                  text-align: center;
                  border-radius: 2px;
                  border: 1px solid $gray-400;
                  margin-right: 2.1rem;

                  path {
                    display: none;
                  }
                }

                .ais-RefinementList-count {
                  margin-left: 0.3rem;
                }

                &.checked {

                  .checkbox-label {
                    border: 1px solid $black;
                  }

                  path {
                    display: block;
                  }
                }
              }
            }
          }

          // Catalogue menu
          .ais-HierarchicalMenu {

            .ais-HierarchicalMenu-item {
              margin: 1rem 0 0 1.4rem;
              font-size: 1.3rem;

              a.ais-HierarchicalMenu-link {
                color: $black;

                &:hover {
                  color: $primary;
                }

                .cross-circle {
                  display: none;
                }
              }
            }
          }

          // Price
          .ais-RangeSlider {
            margin: 0 4rem;

            .rheostat-progress {
              background-color: $primary;
            }
          }
        }

        &.is-widget-container-product_brand {

          .ais-Panel-body {

            .ais-RefinementList-list {

              .ais-RefinementList-item:nth-child(n+6) {
                display: none;
              }
            }
          }

          .ais-Panel-footer {

            .see-all-brand {
              text-decoration: underline;
              border: none;
              font-size: 1.3rem;
              background: $white;
              padding: 0 0 0 1.4rem;

              &:hover {
                color: $primary;
              }

              &:active {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
