@charset "UTF-8";
// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6rem !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;

  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;

    &::before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled::before {
    opacity: $slick-opacity-not-active;
  }

  &::before {
    font-size: 20px;
    line-height: 1;
    color: $white;
    opacity: $slick-opacity-default;
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 0.5rem;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 0.5rem;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;

        &::before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-size: $slick-dot-size;
        line-height: 20rem;
        text-align: center;
        color: $black;
        opacity: $slick-opacity-not-active;
      }
    }

    &.slick-active button::before {
      color: $black;
      opacity: $slick-opacity-default;
    }
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  .dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track::before,
.slick-track::after {
  display: table;
  content: '';
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  img {
    display: block;
  }

  .slick-loading {

    .slick-slide {
      visibility: hidden;
    }

    img {
      display: none;
    }
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
