%mgz-column-styles {
    background-color: $white;
    border: 1px solid $range-tiles-border-gray;
    border-radius: 5px;
    min-height: 390px;
    max-width: 350px;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        width: calc(25% - 20px);
        margin-bottom: 0;
        min-height: 436px;
    }

    @include media-breakpoint-up(xl) {
        width: calc(25% - 30px);
        min-width: 300px;
    }

    .mgz-element-text {
        padding: 1.9rem 2.3rem;
    }

    .mgz-element-single_image {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $drop-down-gray;
        border-radius: 5px;

        .mgz-element-inner {
            margin: 0;
        }

        img {
            height: 178px;
            padding: 1rem;
            width: 100%;
            object-fit: cover;
        }
    }
}

.cms-page-view {

    .page-main {

        @include media-breakpoint-up(lg) {
            max-width: 1530px;
        }

        .magezon-builder .mgz-container {
            width: 100%;
        }

        .mgz-element-column>.mgz-element-inner {
            padding: 0;
        }

        .category {

            &-banner {
                padding: 0 0 1.8rem;

                @include media-breakpoint-up(lg) {
                    padding: 2rem 0 5rem;
                }

                img {
                    height: 450px;

                    @include media-breakpoint-up(lg) {
                        height: auto;
                    }
                }

                .image-title {
                    font-size: $h1-font-size;
                    line-height: 1.15;
                    margin-bottom: 1.8rem;

                    @include media-breakpoint-up(lg) {
                        font-size: 5.2rem;
                        margin-bottom: 2.3rem;
                    }
                }

                .image-description {
                    font-size: 1.6rem;
                    max-width: 976px;
                    margin: 0 auto;
                    padding: 0 1.8rem;

                    @include media-breakpoint-up(lg) {
                        font-size: 2rem;
                        line-height: 1.8;
                    }
                }
            }

            &-tiles {
                max-width: 1259px;
                margin: 0 auto;
                padding: 0 1.6rem;

                @include media-breakpoint-up(lg) {
                    padding: 0;
                }

                &:nth-child(even) {
                    .mgz-container {
                        flex-direction: column-reverse;

                        @include media-breakpoint-up(lg) {
                            flex-direction: row;
                        }

                    }
                }

                &.last {
                    margin-bottom: 8rem;

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 16.3rem;
                    }
                }

                .mgz-container {
                    display: flex;
                    align-items: center;
                    margin-bottom: 3rem;
                    flex-direction: column;

                    @include media-breakpoint-up(lg) {
                        gap: 4px;
                        flex-direction: row;
                    }

                    &.inner-content:before,
                    &.inner-content:after {
                        content: unset;
                    }

                    .mgz-element-column {

                        .mgz-element-single_image {
                            max-height: 398px;
                        }

                        .mgz-element-text {
                            max-width: 100%;
                            margin: 0 auto 1.5rem;

                            @include media-breakpoint-up(lg) {
                                margin: 0 auto;
                                max-width: 380px;
                            }

                            h2 {
                                font-size: $h2-font-size;
                                margin-bottom: 1.7rem;

                                @include media-breakpoint-up(lg) {
                                    font-size: 3.4rem;
                                }
                            }

                            p {
                                font-size: 1.6rem;
                                line-height: 1.8;
                            }
                        }
                    }
                }
            }

            &-range {
                position: relative;
                padding: 5rem 0 5.8rem;
                max-width: 1290px;
                margin: 0 auto;

                @include media-breakpoint-up(lg) {
                    padding: 5.2rem 0 8.1rem;
                    margin-bottom: 5rem;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: calc((100% - 100vw) / 2);
                    width: 100vw;
                    height: 100%;
                    background: $drop-down-gray;
                }

                &-title {
                    padding: 0 3.2rem;
                    max-width: 645px;
                    margin: 0 auto 1.3rem;

                    @include media-breakpoint-up(lg) {
                        margin: 0 auto 3.1rem;
                    }

                    h2 {
                        color: $red;
                        margin-bottom: 1.4rem;
                    }

                    p {
                        font-size: 2.8rem;
                        line-height: 1.47;
                        text-align: center;
                        font-weight: 700;

                        @include media-breakpoint-up(lg) {
                            font-size: 3.6rem;
                        }
                    }
                }

                .mgz-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: initial;
                    justify-content: center;

                    @include media-breakpoint-up(lg) {
                        gap: 20px;
                    }

                    &.inner-content:before,
                    &.inner-content:after {
                        content: unset;
                    }

                    .mgz-element-column {

                        &:not(:first-child) {
                            @extend %mgz-column-styles;
                        }

                        h3 {
                            font-size: 2.9rem;
                            line-height: 1.4;
                            position: relative;
                            margin-bottom: 1.1rem;
                        }
                    }
                }

               &.first-column {
                .mgz-element-column:first-child {
                    @extend %mgz-column-styles;
                }
               }
            }
        }
    }
}