
.topbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .header {
    
    border-right: none;
    background: $body-bg;
    width: 100%;
    max-width: 324px;
    display: flex;
    align-items: center;

    &.sameday { 
      max-width: 280px;
    }

    & > div {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      font-family: $font-family-base;
      justify-content: center;
      border-right: $border-width solid #b5b7b9;
    }

    .title {
      color: $primary;
      text-transform: uppercase;
      text-align: left;
      font-size: 1.4rem;
      font-weight: 900;
      line-height: 1.9rem;
      margin: 0.5rem 0 -0.5rem;
    }

    .title-bottom {
      font-size: 1.2rem;
      font-weight: 600;
      color: $black;

      a {
        text-decoration: underline;
        color: $black !important;
      }
    }

    svg {
      margin: 1rem 0.8rem 0 0;
    }
  }

  .prefer-chat {
    box-sizing: border-box;
    background: $primary;
    color: $body-bg;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;

    .phone {
      font-size: 2.2rem;
      font-weight: 600;
      font-family: $headings-font-family;
      color: $white;
      text-decoration: none;
    }

    .chat {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 324px;
      padding-left: 4rem;

      .prefer {
        padding: 0.7rem;
        font-size: 1.2rem;
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5.1rem 2.4rem 0 0;
      border-color: $body-bg transparent transparent transparent;
      margin-left: -0.1rem;
    }
  }

  @include media-breakpoint-down(lg) {

    .header,
    .prefer-chat {
      width: 25%;
    }

    .header {

      &.sameday { width: 25%; }

      .img {
        padding: 0.5rem 0.5rem 0;
      }
    }

    .prefer-chat {
      width: 25%;

      .chat {
        flex-direction: column;
        padding: 0.3rem 0.7rem;

        .prefer {
          padding: 0 0.7rem;
        }

        .phone {
          font-size: 1.6rem;
          padding: 0 0.4rem;
        }
      }
    }
  }

  @media (max-width: 810px) {
    & { display: none; }
  }
}
