.product-range-tiles {
    max-width: 1323px;
    margin: 2rem auto 7rem;
    padding: 0 32px;

    @include media-breakpoint-up(lg) {
        margin: 2rem auto 7.9rem;
    }

    .mgz-child:not(:last-child)>.mgz-element-inner {
        margin: 0;
    }

    .mgz-element-column>.mgz-element-inner {
        padding: 0;
    }

    h3 {
        font-size: 2.9rem;
        line-height: 1.4;
        position: relative;
        margin-bottom: 1.5rem;

        &::after {
            content: '';
            width: 32px;
            height: 32px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../images/icon-arrow-right.svg');
            background-size: cover;
            pointer-events: none;
        }

        a {
            display: block;
            width: 100%;
        }
    }

    .mgz-element-heading {
        margin-top: 1.1rem;
    }

    &-tiles ul li,
    &-shopall {
        a {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
            padding: 1.1rem 1.3rem;
            border-radius: 100px;
            min-width: 115px;
            text-align: center;

            &:hover {
                font-weight: 400;
            }
        }
    }

    &-shopall {
        margin-bottom: 2.7rem;

        a {
            background-color: $secondary;
            color: $white;
        }
    }

    &-tiles {
        margin-top: 3.3rem;

        @include media-breakpoint-down(lg) {
            &.faded {
                max-height: 1940px;
                overflow: hidden;
                position: relative;

                &::after {
                    content: '';
                    background: linear-gradient(180deg, #FFFFFF00 95%, #FFFFFF 99%) 0% 0% no-repeat padding-box;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                }
            }
        }

        >.mgz-element-inner:before {
            display: none;
        }

        .inner-content.mgz-container {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            position: relative;

            @include media-breakpoint-down(lg) {
                justify-content: center;
            }

            &::before {
                display: none;
            }

            .mgz-col-md-3 {
                width: 100%;

                @include media-breakpoint-up(lg) {
                    width: calc(25% - 20px);
                    max-width: 300px;
                }

                >.mgz-element-inner {
                    border: 1px solid $range-tiles-border-gray;
                    padding: 0;
                    border-radius: 5px;

                    .mgz-element-single_image {
                        display: flex;
                        justify-content: center;
                        background-color: $drop-down-gray;
                        padding: 2rem 0 1.2rem;

                        img {
                            mix-blend-mode: multiply;
                        }
                    }

                    > :not(.mgz-element-single_image) {
                        padding: 0 1.1rem;
                    }

                    p {
                        color: $red;
                        font-weight: 700;
                        margin-bottom: 1.6rem;
                    }
                }
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                max-width: 50%;
                border: none;
                margin-bottom: 1.2rem;

                &:nth-child(odd) {
                    margin-right: 1rem;

                    @include media-breakpoint-up(lg) {
                        margin-right: 1.6rem;
                    }
                }

                a {
                    background: $drop-down-gray;
                }
            }
        }
    }

    .btn-show-more {
        display: block;
        border-radius: $border-radius-sm;
        border: $gray-800 solid $border-width;
        padding: 0.8rem 1.3rem;
        text-decoration: none;
        color: $gray-800;
        font-size: 1.2rem;
        font-family: $headings-font-family;
        background: $white;
        line-height: 1;
        max-width: 120px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &:hover {
            color: $white;
            background-color: $gray-800;
        }
    }
}