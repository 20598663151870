.btn:not(.btn-link),
.action {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-white,
.btn-outline-white {
  color: $dark;
}

.btn-primary {

  &:hover {
    background-color: $white;
    border-color: $primary;
    color: $primary;
  }
}

.btn-secondary {

  &:hover {
    background-color: $white;
    border-color: $secondary;
    color: $secondary;
  }
}

.action {
  border-width: $btn-border-width !important;

  &::before {
    display: none !important;
  }

  // Including overwriting Magento garbage
  &.primary,
  &.secondary {
    border-radius: $btn-border-radius;
    /* stylelint-disable-line */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $btn-padding-y $btn-padding-x;
    font-family: $btn-font-family;
  }

  &.primary {
    @include button-variant($primary, $primary, $hover-background: darken($primary, 7.5%), $hover-border: darken($primary, 10%), $active-background: darken($primary, 10%), $active-border: darken($primary, 12.5%));
    /* stylelint-disable-line */

    &:hover {
      background-color: $white;
      border-color: $primary;
      color: $primary;
    }
  }

  &.secondary {
    @include button-variant($secondary, $secondary, $hover-background: darken($secondary, 7.5%), $hover-border: darken($secondary, 10%), $active-background: darken($secondary, 10%), $active-border: darken($secondary, 12.5%));
    /* stylelint-disable-line */

    &:hover {
      background-color: $white;
      border-color: $secondary;
      color: $secondary;
    }
  }

  &.clear,
  &.back,
  &.link {
    background: none;
    border: none;
    text-decoration: underline;
    font-weight: normal;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.continue,
  &.update {
    padding-left: $btn-padding-x !important;
    padding-right: $btn-padding-x !important;
  }
}

.btn-lg {

  &.action.action.action {
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    line-height: $btn-line-height-lg;
  }
}

.btn-outline-secondary {

  &.action.action.action {
    background: none;
    border-color: $secondary;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }
}

.btn-outline-dark {
  background-color: $white;
}

%sidebar-button {
  padding: 1.2rem 4.2rem;
  display: flex;
  align-items: center;
  color: $black;
  background-color: $body-bg;
  border-color: $black;
  border-width: 2px;
  box-shadow: 0 3px 6px #0000001a;

  &:focus,
  &:active,
  &:hover {
    box-shadow: 0 0 0 0.2rem $black !important;
    color: $body-bg !important;
    background-color: $black !important;
    border-color: $black !important;
  }

  span {
    line-height: 1.5rem;
    font-size: 1.5rem;
  }
}

.mgz-carousel.owl-carousel .owl-nav {
  button {
    &.owl-next {
      right: -60px;
    }

    &.owl-prev {
      left: -60px;
    }
  }
}

%read-more-btn {
  border: 1px solid $black;
  border-radius: 2px;
  font-size: 1.2rem;
  color: $gray-800;
  padding: 0.6rem 3.4rem;
  letter-spacing: 0;
  box-shadow: $btn-box-shadow-bottom;

  &:hover {
    color: $white;
    background: $gray-800 !important;
  }

  &:focus,
  &:active {
    background: $white;
    box-shadow: none !important;
  }
}

.btn-download-catalogue {
  background-color: transparent;
  color: $white;
  padding: 1.6rem 4.1rem 1.6rem 5.1rem;
  text-align: end;
  font-size: 1.8rem;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
  position: relative;
  border: 1px solid $white;
  border-radius: 4px;
  font-family: $headings-font-family;

  @include media-breakpoint-up(lg) {
    max-width: 274px;
  }

  &:hover {
    background-color: $white;
    color: $primary !important;
    
    &::before {
      background-image: url(../images/icon-arrow-right-primary.svg);
    }
  }

  &::before {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    display: block;
    left: 42px;
    top: 7px;
    transform: rotate(45deg);
    background-image: url(../images/icon-arrow-right-white.svg);
    background-size: cover;
  }
}