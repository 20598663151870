.aa-Panel {
  position: fixed !important;
  z-index: 9 !important;
  width: 100% !important;
  top: 140px !important;
  right: unset !important;
  left: 0!important;
  padding-left: 0!important;

  @include media-breakpoint-down(md) {
    top: 134px !important;
  }

  .footer_algolia {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .aa-Panel.productColumn1 .aa-PanelLayout section:nth-child(1){
      margin-left: auto !important;
  }
}

@media (min-width: 769px) {
  .aa-Panel.productColumn2 .aa-PanelLayout section:nth-child(1){
      width: 100% !important;
  }
}

.aa-PanelLayout {
  background: $body-bg;
  width: 100%;
  max-width: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 100%;
  border: 0;
  padding-bottom: 0 !important;

  section:nth-child(1) {
    margin-left: auto !important;
  }

  @include media-breakpoint-up(lg) {

    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }

  @include media-breakpoint-down(lg) {
    box-shadow: 0 3px 6px #00000029;
    max-height: calc(100vh - 136px);
    overflow-y: auto;
  }

  .aa-SourceNoResults {
    max-width: 936px;
    margin: 0 auto;
    width: fit-content;

    .aa-no-results-products {
      padding: 40px 40px;
      min-height: 250px;
      width: fit-content;
      margin: auto;

      .see-all a {
        color: #636363;
        font-weight: bold;
      }

      .suggestions {
        margin-bottom: 30px;
      }

      .title {
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 16px;
      }
    }
  }


  section[data-autocomplete-source-id="products"] {
    max-width: 936px;
    min-height: 401px;
    margin: 0 auto;
    min-width: unset !important;
    border-left: unset !important;
    margin-left: auto !important;
    grid-area: unset !important;
    float: left;
    width: 100%;

    .aa-List {
      display: flex !important;
      flex-wrap: wrap;
      margin: 2.5rem auto 1.2rem;

      .aa-Item {
        max-width: 276px;
        margin: 0 auto;
        width: 100%;

        @include media-breakpoint-down(xs) {
          margin: 0 0 1.8rem;
          max-width: 100%;
        }

        a {
          padding: 1.5rem 2rem;
          width: 100%;
        }

        .info {
          white-space: normal;
          font-size: 1.3rem;

          .algoliasearch-autocomplete-category {
            display: none;
          }

          .algoliasearch-autocomplete-price {
            color: $black;
          }
        }
      }
    }

    .aa-SourceFooter {

      #autocomplete-products-footer {
        position: sticky;
        left: 0;
        margin: 0 auto 5rem;
        background: $body-bg;
        display: block;
        width: 100%;
        bottom: auto;

        .bottom-text {
          padding: 0.6rem 5.5rem;
          border: $input-btn-border-width solid $black;
          border-radius: 2px;
          transition: all 0.5s ease;

          @include media-breakpoint-down(xs) {
            padding: 0.6rem 4rem;
          }

          a {
            font-size: 1.2rem;
            color: map-get($grays, '800');
          }

          &:hover {
            background: map-get($grays, '800');

            a {
              color: $body-bg;
            }
          }
        }
      }
    }
  }

  section[data-autocomplete-source-id="categories"],
  section[data-autocomplete-source-id="pages"],
  #algoliaFooter {
    display: none;
  }
}
