.contact-index-index {

  .page.messages {
    max-width: 581px;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
      padding: 0 3.2rem;
    }
  }

  .field.note.no-label {
    max-width: 510px;
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 1.45;

    @include media-breakpoint-down(lg) {
      margin-bottom: 5rem;
      line-height: 1.82;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
  }

  .contact-main-wrapper .tab-content {
    padding-top: 0;

    legend {
      margin-bottom: 3.2rem !important;

      @include media-breakpoint-down(sm) {
        margin-bottom: 2.4rem !important;
      }
    }
  }

  .page-footer {

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
  }

  .page-title-wrapper {
    margin-bottom: 1.4rem;

    .page-title {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      padding-left: 3.2rem;

      .page-title span {
        font-size: 2.5rem;
      }
    }
  }

  .page-main {
    max-width: 1366px;
    padding: 6.1rem 0 11.1rem 10.2rem;

    @include media-breakpoint-down(lg) {
      padding: 4.4rem 0 0 0;
    }
  }

  .location-banner {
    position: relative;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    background-image: url('../images/contact-us-map-min.png');
    background-position: center;
    background-size: auto 320px;
    background-repeat: no-repeat;
    height: 320px;
  }

  .location-banner-mobile {
    display: none;
    position: relative;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    background-image: url('../images/contact-us-map-min.png');
    background-position: center;
    background-size: auto 400px;
    background-repeat: no-repeat;
    height: 400px;
  }

  @include media-breakpoint-down(sm) {

    .location-banner-mobile {
      display: block;
    }

    .location-banner {
      display: none;
    }
  }

  .columns .column.main {
    padding-bottom: 0;
  }

  .column .contact-main-wrapper {
    display: flex;
    flex-direction: row;

    .form.contact {
      min-width: auto;
      max-width: 691px;
      width: 100%;

      .actions-toolbar button {
        box-shadow: $btn-box-shadow-bottom;
      }

      @include media-breakpoint-down(lg) {
        padding: 0 3.2rem;

        .actions-toolbar {
          float: left;
          margin-bottom: 7.6rem;
        }
      }
    }

    .name-field-wrapper {
      display: flex;
      flex-direction: row;

      .field.name {
        max-width: 283px;
        width: 100%;

        &:first-child {
          margin-right: 1.5rem;
        }

        label {
          font-weight: 700;
        }
      }

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        margin-bottom: 0;

        .field.name {
          max-width: 581px;
          margin-bottom: 2.4rem;

          &:first-child {
            margin-right: 0;
          }
        }
      }
    }

    .field {
      max-width: 581px;
    }

    .address-wrapper {
      display: flex;
      flex-direction: column;
    }

    .contact-us-right {
      padding-left: 4.9rem;
      padding-right: 3.1rem;
      padding-top: 6.1rem;
      margin-top: -12.3rem;
      background-color: $drop-down-gray;
      max-width: 400px;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      h2 {
        font-size: 1.8rem;
        line-height: 1.33;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }

      h2.address-header {
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 2.4rem;
        max-width: 278px;
      }

      .phone {
        font-size: 4.5rem;
        line-height: 6.7rem;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 4rem;
        font-family: $headings-font-family;
        display: block;
      }

      .phone:hover {
        text-decoration: none;
        color: $primary;
      }

      .trading-info {
        padding-left: 3.4rem;
        padding-bottom: 3.4rem;

        > div {
          margin-bottom: 1.6rem;
          position: relative;

          span {
            display: block;
            line-height: 1.71;
          }

          .header {
            font-weight: 700;
          }
        }

        > div::before {
          content: '';
          background-position: center;
          background-size: 24px 24px;
          background-repeat: no-repeat;
          position: absolute;
          width: 24px;
          height: 24px;
          left: -34px;
        }

        .address::before {
          background-image: url('../images/location.svg');
        }

        .open-hours::before,
        .christmas::before {
          background-image: url('../images/clock.svg');
        }

        .christmas .header {
          color: $primary;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;

      .contact-us-right {
        margin-top: 0;
        max-width: 100%;
        padding-top: 4.3rem;
      }
    }
  }
}
