%product_label {

  span {
    font-size: 1rem;
    padding: 0.3rem 1.1rem;
    display: table-cell;
    vertical-align: middle;
  }

  &.on_sale {
    background: $primary;
  }

  &.call_for_price {
    background: #ffa439;
  }

  &.clearance {
    background: $blue;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 2.1rem 0.9rem 0 0;
    position: absolute;
    margin-left: -0.1rem;
  }

  &.on_sale::after {
    border-color: $primary transparent transparent transparent;
  }

  &.call_for_price::after {
    border-color: #ffa439 transparent transparent transparent;
  }

  &.clearance::after {
    border-color: $blue transparent transparent transparent;
  }
}

%product_button_call_for_price {

  .call-for-price-btn {
    border: 1px solid $gray-800;
    border-radius: 2px;
    color: $gray-800 !important;
    text-decoration: none;
    width: 100%;
    height: 30px;
    font-size: 1.2rem;
    font-family: $headings-font-family;
    background: $body-bg;
    text-transform: uppercase;
    padding: 0.7rem;
    display: block;
    white-space: nowrap;
    display: flex;
    justify-content: center;

    &:hover {
      text-decoration: none;
      color: $gray-800;
    }
  }
}

.sale-on-now,
.featured-products {
  max-width: 1119px;
  margin: 0 auto;
  margin: 1.4rem auto 2.5rem;

  @include media-breakpoint-up(lg) {
    margin: 2.9rem auto 1rem;
  }

  @extend .mgz-block-content;

  .owl-dots {
    margin-top: 3rem !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 1119px;

    @include media-breakpoint-down(sm) {
      max-width: 350px;
    }

    .owl-dot {
      width: 100%;
      flex: 1 1 auto;

      span {
        width: 100%;
        background: $gallery-nav-gray;
        height: 0.3rem;
        border-radius: 0;
        margin: 0.8rem 0;
      }

      &.active span {
        background: map-get($grays, '800');
      }
    }
  }
}

.mgz-block-content {

  .owl-nav {
    display: block;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .mgz-carousel-nav-position-center_split .owl-nav [class*=owl-] {
    opacity: 1;
  }

  .owl-stage-outer {

    .owl-item {

      .product-item {
        margin: 0 auto;
        position: relative;

        @include media-breakpoint-down(sm) {
          max-width: 160px;
        }

        .product_label {
          color: $body-bg;
          position: absolute;
          top: 2rem;
          left: 2.2rem;
          z-index: 3;
          display: table;

          @extend %product_label;
        }

        .product-item-info {

          .product-image-container {

            .product-image-photo {
              width: auto;
              height: auto;
            }
          }

          .product-item-details {
            width: 200px;
            margin: 1.5rem auto 0;

            @include media-breakpoint-down(sm) {
              width: 160px;
            }

            .product-item-name {
              min-height: 38px;
              text-align: center;

              @include media-breakpoint-down(sm) {
                min-height: 56px;
              }

              .product-item-link {
                font-size: 1.4rem;
                color: $black;
              }
            }

            .product-item-sku {
              font-size: 1.3rem;
              color: $black;
              text-align: center;
            }

            .product-item-actions {
              display: flex;
              justify-content: center;

              .actions-primary {
                width: 100%;
                max-width: 120px;

                .action.primary {
                  width: 100%;
                  height: 30px;
                  font-size: 1.2rem;
                  font-family: $headings-font-family;
                  background: map-get($grays, '800');
                  border-color: map-get($grays, '800');

                  &:hover {
                    background: white;
                    color: map-get($grays, '800');
                  }

                  &:focus {
                    box-shadow: none;
                  }
                }

                @extend %product_button_call_for_price;
              }
            }

            .price-box {
              display: flex;
              flex-direction: column-reverse;
              margin: 1rem 0 1.5rem;
              min-height: 53px;
              text-align: center;

              .price-container {

                .price {
                  font-size: 1.8rem;
                  color: $black;
                }
              }

              .old-price {

                .price-container {
                  color: $old-price-gray;

                  .price-label,
                  .price {
                    color: $old-price-gray;
                  }

                  .price {
                    font-size: 1.2rem;
                    text-decoration: line-through;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
