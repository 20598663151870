.page-wrapper {
  position: relative;

  #gotop {
    position: fixed;
    height: 5rem;
    width: 5rem;
    border-radius: $border-radius;
    right: 6.5rem;
    bottom: 6.5rem;
    background: white;
    z-index: 3;
    cursor: pointer;
  }
}

@include media-breakpoint-down(sm) {

  #gotop {
    display: none !important;
  }
}
