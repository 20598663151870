.input-text,
.select {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x $input-padding-y;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  @include font-size($input-font-size); /* stylelint-disable-line */
  @include border-radius($input-border-radius, 0); /* stylelint-disable-line */
  @include box-shadow($input-box-shadow); /* stylelint-disable-line */
  @include transition($input-transition); /* stylelint-disable-line */

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  @include form-control-focus($ignore-warning: true);

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    label + & {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include media-breakpoint-down(xs) {
    background-position: right 1.5rem center;
  }
}

textarea.input-text {
  height: auto;
}

.label {
  font-size: 1.2rem;
}

select {
  appearance: none;
  background-image: url('../images/down.svg');
  background-size: 24px 24px;
  background-position: right 1.3rem center;
  background-repeat: no-repeat;

  &:invalid {
    color: $input-placeholder-color;
  }
}

input,
select,
textarea {

  &.mage-error {
    border-color: $danger;
  }
}

div.mage-error {
  width: 100%;
  margin-top: 0;
  padding-left: 0.2rem;
  font-size: 1.1rem;
}

.required .label.label.label::after {
  content: '*';
  font-size: 1.2rem;
  margin: 0 0 0 0.3rem;
  color: $danger;
}

.field._error .control {

  input,
  select,
  textarea {
    border-color: $danger;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] { font-size: 1.6rem !important; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] { font-size: 1.6rem !important; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] { font-size: 1.6rem !important; }
}
