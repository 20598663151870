.catalogue {

    &-popup {
        position: fixed;
        bottom: 36px;
        right: 26px;
        max-width: 280px;
        width: 100%;
        z-index: 99;

        @include media-breakpoint-up(lg) {
            bottom: 125px;
        }

        &-content {
            position: relative;

            .mgz-element-column>.mgz-element-inner {
                padding: 0;
            }

            p,
            a {
                font-size: 1.6rem;
                text-transform: capitalize;
                margin: 0;
            }

            button {
                position: absolute;
                width: 30px;
                height: 30px;
                top: -47px;
                right: 0;

                &.close {
                    opacity: 1;
                }
            }

            .download-wrapper,
            .finance-btn-wrapper {
                box-shadow: 0px 0px 50px #00000033;
            }

            .finance-btn-wrapper {
                margin-bottom: 1.2rem;
                border-radius: 10px;

                .btn-finance .mgz-btn {
                    color: $white;
                    background-color: $primary;
                    font-size: 1.6rem;
                    width: 100%;
                    font-weight: 700;
                    padding: 1.2rem 0;
                }
            }

            .download-wrapper {
                border: 3px solid $gray-400;
                background-color: $white;
                border-radius: 10px;

                p {
                    color: #242425;
                }

                a {
                    text-decoration: none;
                    color: $red;
                    font-weight: 700;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .mgz-container {
                    padding: 10px 10px 0 15px;
                    display: flex;
                    align-items: center;

                    @include media-breakpoint-up(lg) {
                        padding: 10px 30px 0 15px;
                    }
                }

                .mgz-element {
                    &-single_image {
                        width: 94px;
                    }

                    &-text {
                        margin-left: 0;
                        text-align: center;

                        @include media-breakpoint-up(lg) {
                            margin-left: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}