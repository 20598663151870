.text-normal {
  font-family: $font-family-base;
}

.text-headings {
  font-family: $headings-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase;
}
