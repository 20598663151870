.spinner-lg {
  width: 4rem;
  height: 4rem;
}

.admin__data-grid-loading-mask .spinner,
.fotorama__spinner.fotorama__spinner--show {
  display: inline-block;
  background-image: none !important;
  vertical-align: text-bottom;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  color: $primary !important;
  width: 4rem;
  height: 4rem;
  transform: none;
  font-size: 1rem;
}
