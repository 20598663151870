@import '../components/product-slider';

.cms-index-index {

  .page-wrapper {

    @include media-breakpoint-down(sm) {

      .page-header {
        margin-bottom: 0;
      }
    }

    .page-main {
      padding: 0;

      @include media-breakpoint-up(lg) {
        max-width: 1536px;

        .mgz-container {
          width: 100%;
        }

        .magezon-builder {

          .sale-on-now {
            max-width: 1119px;
            margin: 0 auto;
          }

          .brands-stock {
            max-width: 1130px;
            margin: 0 auto;
          }

          .featured-products {
            max-width: 1119px;
            margin: 0 auto;
          }
        }
      }

      .magezon-builder {

        .mgz-block-heading {
          margin-bottom: 3.4rem;
        }

        .hero-banner {
          padding: 0 0 1.4rem;
          max-width: 1536px;

          @include media-breakpoint-up(lg) {
            padding: 2rem 1.8rem 1.4rem;
          }

          @include media-breakpoint-down(md) {
            &-desktop {
              display: none;
            }
          }

          &-mobile {
            display: none;

            @include media-breakpoint-down(md) {
              display: block;
              margin-top: 1rem;
            }

            @include media-breakpoint-down(sm) {
              margin-top: 0;
            }

            .mgz-element-inner {
              padding: 0;
            }

            #download-wrapper {
              position: relative;

              .mgz-hover-main {
                object-fit: cover;
              }

              .image-description {
                position: absolute;
                bottom: -18.5rem;
                left: 0;
                font-size: 1.8rem;
                font-family: $headings-font-family;
                color: $white;
                background: linear-gradient(to right, $primary, #e61b23);
                padding: 1.2rem 1.8rem;
                height: 4rem;
                display: flex;
                flex-direction: row;

                svg {
                  margin-right: 1.2rem;
                }

                &::before {
                  width: 1.4rem;
                  height: 1.4rem;
                  margin: -0.3rem 1.2rem 0 0;
                }
              }
            }
          }

          .owl-item-image {
            height: auto;
            object-fit: contain;

            @include media-breakpoint-up(lg) {
              height: 420px;
              object-fit: initial;
            }
          }

          #download-wrapper {
            position: relative;

            .mgz-hover-main {
              height: 420px;
              object-fit: cover;
            }

            .image-description {
              position: absolute;
              bottom: -6.5rem;
              left: 0;
              font-size: 1.8rem;
              font-family: $headings-font-family;
              color: $white;
              background: linear-gradient(to right, $primary, #e61b23);
              padding: 1.2rem 1.8rem;
              height: 4rem;
              display: flex;
              flex-direction: row;

              svg {
                margin-right: 1.2rem;
              }

              &::before {
                width: 1.4rem;
                height: 1.4rem;
                margin: -0.3rem 1.2rem 0 0;
              }
            }
          }

          .mgz-element-column>.mgz-element-inner {
            padding: 0;
          }

          [data-type='image'] {
            width: 100%;
          }
        }

        .hero-banner,
        .product-range,
        .welding-process {
          .mgz-child:not(:last-child)>.mgz-element-inner {
            margin: 0;
          }
        }

        .brands-stock {
          padding: 4rem 0 0;

          @include media-breakpoint-up(lg) {
            padding: 4.9rem 0;
          }

          .mgz-block-heading {
            margin-bottom: 3rem;
            padding: 0;
          }

          .logo-slider {

            img {
              max-height: 7.9rem;
              object-fit: cover;
            }
          }

          .owl-dots {
            margin-top: 3rem !important;
            display: none;

            @include media-breakpoint-down(md) {
              display: block;
            }

            .owl-dot {

              span {
                height: 10px;
                width: 10px;
                background: #d6d6d6;
              }

              &.active span {
                background: $primary;
              }
            }
          }
        }

        .product-range {
          max-width: 1323px;
          margin: 2rem auto 7rem;
          padding: 0 32px;

          @include media-breakpoint-up(lg) {
            margin: 2rem auto 7.9rem;
          }

          .mgz-element-column>.mgz-element-inner {
            padding: 0;
          }

          h3 {
            font-size: 2.9rem;
            line-height: 1.4;
            position: relative;
            margin-bottom: 1.5rem;

            &::after {
              content: '';
              width: 32px;
              height: 32px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              background-image: url('../images/icon-arrow-right.svg');
              background-size: cover;
              pointer-events: none;
            }

            a {
              display: block;
              width: 100%;
            }
          }

          .mgz-element-heading {
            margin-top: 1.1rem;
          }

          &-tiles ul li,
          &-shopall {
            a {
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1;
              padding: 1.1rem 1.3rem;
              border-radius: 100px;
              min-width: 115px;
              text-align: center;

              &:hover {
                font-weight: 400;
              }
            }
          }

          &-shopall {
            margin-bottom: 2.7rem;

            a {
              background-color: $secondary;
              color: $white;
              border: 1px solid $secondary;

              &:hover {
                color: $secondary;
                background-color: $white;
              }
            }
          }

          &-tiles {
            margin-top: 3.3rem;

            @include media-breakpoint-down(lg) {
              &.faded {
                max-height: 1940px;
                overflow: hidden;
                position: relative;

                &::after {
                  content: '';
                  background: linear-gradient(180deg, #FFFFFF00 95%, #FFFFFF 99%) 0% 0% no-repeat padding-box;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  pointer-events: none;
                }
              }
            }

            >.mgz-element-inner:before {
              display: none;
            }

            .inner-content.mgz-container {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              position: relative;

              @include media-breakpoint-down(lg) {
                justify-content: center;
              }

              &::before {
                display: none;
              }

              .mgz-col-md-3 {
                width: 100%;

                @include media-breakpoint-up(lg) {
                  width: calc(25% - 20px);
                  max-width: 300px;
                }

                >.mgz-element-inner {
                  border: 1px solid $range-tiles-border-gray;
                  padding: 0;
                  border-radius: 5px;

                  .mgz-element-single_image {
                    display: flex;
                    justify-content: center;
                    background-color: $drop-down-gray;
                    padding: 2rem 0 1.2rem;

                    img {
                      mix-blend-mode: multiply;
                    }
                  }

                  > :not(.mgz-element-single_image) {
                    padding: 0 1.1rem;
                  }

                  p {
                    color: $red;
                    font-weight: 700;
                    margin-bottom: 1.6rem;
                  }
                }
              }
            }

            ul {
              display: flex;
              flex-wrap: wrap;

              li {
                max-width: 50%;
                border: none;
                margin-bottom: 1.2rem;

                &:nth-child(odd) {
                  margin-right: 1rem;

                  @include media-breakpoint-up(lg) {
                    margin-right: 1.6rem;
                  }
                }

                a {
                  background: $drop-down-gray;
                }
              }
            }
          }

          .btn-show-more {
            display: block;
            border-radius: $border-radius-sm;
            border: $gray-800 solid $border-width;
            padding: 0.8rem 1.3rem;
            text-decoration: none;
            color: $gray-800;
            font-size: 1.2rem;
            font-family: $headings-font-family;
            background: $white;
            line-height: 1;
            max-width: 120px;
            width: 100%;
            display: block;
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translateX(-50%);

            @include media-breakpoint-up(lg) {
              display: none;
            }

            &:hover {
              color: $white;
              background-color: $gray-800;
            }
          }
        }

        .range-subtitle {
          padding: 0 3.2rem;
          max-width: 645px;
          margin: 0 auto;

          p {
            font-size: 2.8rem;
            line-height: 1.47;
            text-align: center;
            font-weight: 700;

            @include media-breakpoint-up(lg) {
              font-size: 3.6rem;
            }
          }

          h2 {
            color: $red;
            margin-bottom: 1.4rem;
          }
        }

        .welding-process {
          position: relative;
          padding: 5rem 0 5.8rem;

          @include media-breakpoint-up(lg) {
            padding: 5.2rem 0 8.1rem;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: calc((100% - 100vw) / 2);
            width: 100vw;
            height: 100%;
            background: $drop-down-gray;
          }

          h3 {
            font-size: 1.9rem;
            line-height: 1.47;
            font-weight: 500;
            margin-bottom: 0.9rem;
            text-align: center;

            @include media-breakpoint-up(lg) {
              font-size: 3.4rem;
              margin-bottom: 0.8rem;
            }
          }

          &-tiles {
            padding-top: 3rem;

            @include media-breakpoint-up(lg) {
              padding-top: 4.6rem;
            }

            .inner-content.mgz-container {
              display: flex;
              flex-wrap: wrap;
              gap: 19px;
              position: relative;
              max-width: 1000px;
              margin: 0 auto;
              padding: 0 1.2rem;
              justify-content: center;

              @include media-breakpoint-up(lg) {
                justify-content: start;
                gap: 33px;
              }

              >.mgz-element:before {
                display: none;
              }

              &::before {
                display: none;
              }

              .mgz-element-column {
                width: calc(50% - 11px);
                margin-bottom: 0.4rem;
                box-shadow: 0px 0px 20px #00000029;
                border-radius: 5px;
                background-color: $white;

                @include media-breakpoint-up(lg) {
                  width: calc(33.333% - 33px);
                  max-width: 301px;
                  margin-bottom: 0.5rem;
                }

                &:first-child {
                  min-height: 175px;

                  @include media-breakpoint-up(lg) {
                    min-height: auto;
                  }

                  >.mgz-element-inner {
                    background-color: $red;
                    display: flex;
                    justify-content: start;
                    align-items: end;
                    padding: 0 2rem 2rem 2.5rem;
                    border: none;

                    @include media-breakpoint-up(lg) {
                      padding: 0 2.5rem 3.7rem 4.4rem;
                    }

                    .mgz-element-text {
                      width: 100%;
                      position: relative;

                      &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        height: 35px;
                        width: 35px;
                        border: 3px solid $white;
                        border-radius: 50px;
                        background-image: url('../images/icon-caret-right.svg');
                        background-position: center;
                        background-size: contain;
                        background-color: $white;
                      }
                    }

                    p {
                      font-size: 1.8rem;
                      line-height: 1.4;
                      position: relative;
                      color: $white;
                      max-width: 217px;
                      width: 100%;

                      @include media-breakpoint-up(lg) {
                        font-size: 2.6rem;
                        line-height: 1.53;
                        max-width: 197px;
                      }
                    }
                  }
                }

                &:first-child,
                &:last-child {
                  max-height: 175px;
                  width: 100%;

                  @include media-breakpoint-up(lg) {
                    max-height: 100%;
                    height: inherit;
                  }
                }

                .mgz-element-inner,
                .mgz-element,
                .mgz-btn {
                  position: static;
                }

                >.mgz-element-inner {
                  border: 8px solid $white;
                  padding: 0.5rem 1.5rem;
                  border-radius: 5px;
                  text-align: center;
                  height: 175px;

                  @include media-breakpoint-up(lg) {
                    padding: 1.7rem 0 2.2rem;
                    height: 100%;
                  }

                  .mgz-element-single_image {
                    display: flex;
                    justify-content: center;

                    @include media-breakpoint-down(md) {
                      max-width: 78px;
                      margin: 0 auto;
                    }
                  }
                }
              }
            }

            .mgz-button {
              text-align: center;

              .mgz-btn {
                background-color: $red;
                padding: 0.7rem 1.2rem;
                min-width: 114px;
                margin-top: 1rem;
                font-size: 1.2rem;
                line-height: 1;
                font-weight: 500;
                color: $white;
                border-radius: initial;

                @include media-breakpoint-up(lg) {
                  margin-top: 2.3rem;
                  padding: 1.1rem 2rem;
                  min-width: 154px;
                  font-size: 1.8rem;
                }

                &:hover {

                  &::after {
                    border: 8px solid $red;
                    border-radius: 5px;
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-down(md) {

        .slider-wrapper {
          background: $drop-down-gray;
        }
      }
    }
  }
}
