.nav-toggle {
  display: none;

  @include media-breakpoint-down(md) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    width: 60px;
    margin-left: 0.5rem;
    padding: 0;
    border-radius: 0;

    &::before {
      display: none;
    }

    svg:last-child {
      display: none;
    }

    .nav-open & svg {
      display: none;

      &:last-child {
        display: block;
      }
    }
  }
}

.sidebar-account {

  .sidebar-nav .nav-tabs {
    border-bottom: 0;
    font-weight: bold;
    color: $black;

    a {
      border: none;
      border-radius: 0;
      font-size: 1.3rem;
      padding: 1.3rem 1.1rem;
      display: flex;
      justify-content: center;

      &:not(.active) {
        background: $gray-200;
        font-weight: 400;
        color: $gray-700;
      }
    }
  }

  .tab-content {
    padding-top: 3.6rem;
    color: $black;

    .block-customer-login,
    .form-forget-password {
      margin-top: 5.8rem;

      fieldset {

        .field:first-child {
          margin-bottom: 3.1rem;
        }
      }
    }

    .primary {

      a {
        text-decoration: underline;
        color: $black;
        font-size: 1.3rem;
        letter-spacing: 0;

        span {
          text-transform: none;
        }
      }
    }

    button:not(.link-style) {
      @extend %sidebar-button;
    }

    button.link-style {
      letter-spacing: 0;
      font-size: 1.3rem;
      text-decoration: underline;
      text-transform: none;
      font-weight: 400;

      &,
      &:hover {
        background: $body-bg;
        border: none;
        color: $black;
      }
    }

    .form-create-account {
      min-width: 100%;

      fieldset.info {
        margin: 5.8rem 0 2.3rem;
      }

      fieldset.create.account::after {
        margin-top: 2.9rem;
      }
    }

    .form-forget-password {
      min-width: 100%;

      fieldset {
        margin: 0;
      }
    }

    .control {
      position: relative;

      > span {
        position: absolute;
        top: 11px;
        left: 19px;

        svg {
          width: 22px !important;
        }
      }
    }
  }

  .field {
    position: relative;

    label {
      position: absolute;
      left: 12px;
      top: -9px;
      background: white;
      padding: 0 0.8rem;
      font-size: 1.3rem;
      z-index: 1;

      &::after {
        color: $required-input-label !important;
      }
    }

    .control {

      input[name=firstname],
      input[name=lastname] {
        padding: 1.35rem 2rem;
      }

      input {
        padding: 1.35rem 2rem 1.35rem 4.7rem;
        color: $black;

        &:focus {
          box-shadow: 0 3px 6px #00000029;
          border-color: map-get($grays, '800');
          opacity: 1;
        }
      }
    }
  }
}
