.cms-finance-calculator {

    .calculator-wrapper {
        max-width: 1000px;
        margin: 12rem auto 0;
        position: relative;

        @include media-breakpoint-up(md) {
            margin: 3rem auto 0;    
        }

        .partner-logo-wrapper {
            width: 100%;
            position: absolute;
            right: 0;
            top: -110px;
            padding: 0;
            justify-content: center;

            @include media-breakpoint-up(md) {
                justify-content: end;
                width: 50%;
                right: 42px;
                top: -20px;
            }
            
            img {
                height: auto;
                max-height: 147px;
                z-index: -1;
            }
        }
    }
}